.tableDetail {
  td {
    // padding-left: 4px !important;
    padding-right: 0px !important;
  }
}

.stickyFooter {
  // position: fixed;
  // bottom: 0;
  // right: 0;
  background: white;
  display: flex;
  flex-direction: row-reverse;
}
.dragMenu {
  display: none;
  position: absolute;
  left: 0px;

  flex-direction: column;
  justify-content: space-between;
  span {
    border: 1px solid;
    padding: 2px 4px;
    border-radius: 5px;
    margin-bottom: 2px;
    cursor: pointer;
  }
  &:hover {
    display: flex;
  }
}

.draggable {
  &:focus,
  &:active,
  &:hover {
    .dragMenu {
      position: absolute;
      left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        border: 1px solid #dee2e6;
        padding: 2px 4px;
        border-radius: 5px;
        margin-bottom: 2px;
        color: #8898aa;
        cursor: pointer;
      }
    }
  }
}
