// @media screen and (max-width: 767px) {
//   &:first-child {
//     min-width: 200px;
//   }
//   &:nth-child(4) {
//     min-width: 140px;
//   }
//   &:nth-child(5) {
//     min-width: 100px;
//   }
//   &:last-child {
//     min-width: 140px;
//   }
// }

.modal {
  margin-top: 120px;
  transition: opacity 0s linear !important;
  animation-duration: 0.3s !important;
  animation-fill-mode: both;
  animation-name: show;

  // .modal-dialog {
  //   margin-top: 120px;
  // }
}
