.docs {
  display: inline-block;

  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-right: 12px;
  width: calc(50% - 12px);
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 240px;
  }
}
