//
// Components
//

// Alerts
@import "../core/alerts/alert";
@import "../core/alerts/alert-dismissible";
@import "../core/alerts/alert-notify";

// Avatars
@import "../core/avatars/avatar";
@import "../core/avatars/avatar-group";

// Badges
@import "../core/badges/badge";
@import "../core/badges/badge-circle";
@import "../core/badges/badge-dot";
@import "../core/badges/badge-floating";

// Breadcrumbs
@import "../core/breadcrumbs/breadcrumb";

// Buttons
@import "../core/buttons/button";
@import "../core/buttons/button-brand";
@import "../core/buttons/button-group";
@import "../core/buttons/button-icon";

// Cards
@import "../core/cards/card";
@import "../core/cards/card-animations";
@import "../core/cards/card-blockquote";
@import "../core/cards/card-money";
@import "../core/cards/card-pricing";
@import "../core/cards/card-profile";
@import "../core/cards/card-stats";

// Charts
@import "../core/charts/chart";

// Close
@import "../core/close/close";

// Collapse
@import "../core/collapse/accordion";

// Content
@import "../core/content/main-content";

// Custom forms
@import "../core/custom-forms/custom-form";
@import "../core/custom-forms/custom-control";
@import "../core/custom-forms/custom-checkbox";
@import "../core/custom-forms/custom-radio";
@import "../core/custom-forms/custom-toggle";

// Dropdowns
@import "../core/dropdowns/dropdown";

// Footers
@import "../core/footers/footer";

// Forms
@import "../core/forms/form";
@import "../core/forms/input-group";
@import "../core/forms/form-extend";
@import "../core/forms/form-validation";

// Grid
@import "../core/grid/grid";

// Headers
@import "../core/headers/header";

// Icons
@import "../core/icons/icon";
@import "../core/icons/icon-actions";
@import "../core/icons/icon-shape";

// List groups
@import "../core/list-groups/list-group";
@import "../core/list-groups/list-check";

// Maps
@import "../core/maps/map";

// Masks
@import "../core/masks/mask";

// Medias
@import "../core/medias/media";
@import "../core/medias/media-comment";

// Modals
@import "../core/modals/modal";

// Navbars
@import "../core/navbars/navbar";
@import "../core/navbars/navbar-floating";
@import "../core/navbars/navbar-vertical";
@import "../core/navbars/navbar-search";
@import "../core/navbars/navbar-dropdown";
@import "../core/navbars/navbar-collapse";
@import "../core/navbars/navbar-top";

// Navs
@import "../core/navs/nav";
@import "../core/navs/nav-pills";

// Paginations
@import "../core/paginations/pagination";

// Popovers
@import "../core/popovers/popover";

// Progresses
@import "../core/progresses/progress";

// Rtl
@import "../core/rtl/rtl";

// Separators
@import "../core/separators/separator";

// Sections
@import "../core/sections/nucleo-icons";

// Shortcuts
@import "../core/shortcuts/shortcut";

// Tables
@import "../core/tables/table";
@import "../core/tables/table-actions";
@import "../core/tables/table-sortable";

// Timeline
@import "../core/timeline/timeline";

// Type
@import "../core/type/type";
@import "../core/type/article";
@import "../core/type/display";
@import "../core/type/heading";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap");
.questionWrap {
  .question {
    margin-top: 80px;
    margin-bottom: 80px;

    .card {
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: none;
      border-bottom: 1px solid $secondary !important;

      .card-header {
        border-bottom: none;

        .btn[aria-expanded="true"] {
          .ni {
            transform: rotate(180deg);
          }
        }

        .btn:active:focus {
          box-shadow: none;
        }
        .btn {
          &:focus {
            box-shadow: none !important;
          }
        }
      }

      &:last-child {
        border-bottom: none !important;
      }
      .card-body {
        padding: 10px 50px 30px;
      }
    }
  }
}
.docs {
  p {
    font-size: 14px;
  }
}

.nav-shadow {
  box-shadow: 0 0 10px #ccc;
}
.logo-text {
  font-family: "Barlow Condensed", sans-serif;
  letter-spacing: 0.2em;
}
h1.title {
  font-family: "Barlow Condensed", sans-serif;
}
// #root {
//   min-height: 100vh;
// }

.cursor-pointer {
  cursor: pointer;
}

iframe {
  border: none;
}
p {
  color: #32325d;
}
.table .thead-light th {
  background-color: #f6f9fc;
  color: #32325d;
}

.card .table td,
.card .table th {
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
}

.table td,
.table th {
  font-size: 0.8125rem !important;
  color: #32325d !important;
}
