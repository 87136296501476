.tableDetail {
  td {
    // padding-left: 4px !important;
    padding-right: 0px !important;
  }
}

.stickyFooter {
  // position: fixed;
  // bottom: 0;
  // right: 0;
  background: white;
  display: flex;
  flex-direction: row-reverse;
}

.dragMenu {
  display: none;
  position: absolute;

  z-index: 9999;
  left: 12px;
  flex-direction: column;
  justify-content: space-between;
  span {
    border: 1px solid;
    padding: 2px 4px;
    border-radius: 5px;
    margin-bottom: 2px;
    cursor: pointer;
  }
  &:hover {
    display: flex;
  }
}
.draggable {
  td {
    padding-left: 10px !important;
    &:first-child {
      min-width: 280px;
      input {
        margin-left: 20px;
        width: 260px;
      }
    }
    &:nth-child(2) {
      min-width: 120px;
    }
    &:nth-child(4) {
      min-width: 120px;
    }
    &:nth-child(5) {
      min-width: 100px;
    }
    &:last-child {
      min-width: 140px;
    }

    @media screen and (max-width: 767px) {
      &:first-child {
        min-width: 236px;
        input {
          margin-left: 18px;
          width: 218px;
        }
      }
      &:nth-child(4) {
        min-width: 140px;
      }
      &:nth-child(5) {
        min-width: 100px;
      }
      &:last-child {
        min-width: 140px;
      }
    }
  }
  &:focus,
  &:active,
  &:hover {
    .dragMenu {
      position: absolute;
      left: 18px;

      @media screen and (max-width: 767px) {
        &:first-child {
          min-width: 230px;
          input {
            margin-left: 20px;
            width: 220px;
          }
        }
        &:nth-child(4) {
          min-width: 140px;
        }
        &:nth-child(5) {
          min-width: 100px;
        }
        &:last-child {
          min-width: 140px;
        }
      }
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 0;
      flex-shrink: 0;
      span {
        border: 1px solid #dee2e6;
        padding: 2px 4px;
        border-radius: 5px;
        margin-bottom: 2px;
        color: #8898aa;
        cursor: pointer;

        width: 23px;
      }
      &:hover {
        display: flex;
      }
    }
  }
}
