.errorContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 77px);
  .title {
    font-size: 30vw;
    color: white;
    font-family: "Barlow Condensed", sans-serif;
    @media (min-width: 768px) {
      font-size: 20rem;
    }
  }
}
