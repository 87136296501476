.tableDetail {
  td {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}
.cardHeader {
  border-bottom: none;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.cardBody {
  background: #e4eaf1;
  margin: 0 25px;

  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    padding: 50px 0;
    min-height: 96vw;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.containerPdf {
  width: 595px;
  min-height: 842px;
  background: white;
  padding: 0px 25px;
  transform: scale(1.2);
  margin-bottom: 160px;
  margin-top: 160px;
  @media screen and (max-width: 1280px) {
    transform: scale(1);
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @media screen and (max-width: 1280px) {
    transform: scale(1);
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.7);
    margin-bottom: -100px;
    margin-top: -100px;
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.5);
    margin-bottom: -160px;
    margin-top: -160px;
  }
  @media screen and (max-width: 414px) {
    transform: scale(0.44);
    margin-bottom: -260px;
    margin-top: -260px;
  }
  @media screen and (max-width: 375px) {
    transform: scale(0.44);
    margin-bottom: -260px;
    margin-top: -260px;
  }
  @media screen and (max-width: 321px) {
    transform: scale(0.38);
    margin-bottom: -268px;
    margin-top: -268px;
  }
}
.stickyFooter {
  background: white;
  display: flex;
  flex-direction: row-reverse;
}
