.block {
  display: block;
}

.flex {
  display: flex;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-48 {
  width: 48%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.row {
  position: relative;
  // border-bottom: 1px solid $color-gray;
}

.row__remove {
  display: flex;
  padding: 0;
  position: absolute;
  top: 10px;
  right: -20px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .row:hover &,
  .row:focus-within & {
    opacity: 1;
  }
}
